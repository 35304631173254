.footer-area {
  background-color: $color-darkgray-700;

  --focus-offset-color: #{$color-darkgray-700};

  a {
    color: $color-white;

    &:hover {
      color: $color-darkgreen-200;
    }
  }

  .footer-top {
    .social-media-component {
      border-bottom: 3px solid $color-darkgray-700;
    }

    .link-list-component {
      border-bottom: 3px solid $color-darkgray-700;

      @include media-breakpoint-up(lg) {
        border-left: 3px solid $color-darkgray-900;
      }
    }
  }

  .footer-bottom {
    background-color: $color-darkgray-900;

    --focus-offset-color: #{$color-darkgray-900};

    .copyright {
      color: $color-gray-500;
    }
  }

  .scroll-to-top {
    background-color: $color-darkgray-900;
  }
}
